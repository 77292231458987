import { FC, HTMLAttributes } from 'react'
import { AppLayout, CustomerHeader } from '@/root/modules/admaritime'

const CustomerPageLayout: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return (
    <AppLayout>
      <main className="flex justify-center py-20">
        <section className="min-h-40 w-full rounded-md bg-white md:w-[1160px]">
          <CustomerHeader />
          <section className="flex min-h-36 w-full items-center justify-center p-7">{children}</section>
        </section>
      </main>
    </AppLayout>
  )
}

export default CustomerPageLayout
