import { FC, useState } from 'react'
import ReservationFilter from './components/ReservationFilter'
import Reservation from './components/ReservationsBar'
import { Reservation as ReservationProps } from '@/root/shared-types'

interface FilterWithReservationsProps {
  reservations: ReservationProps[]
}

const FilterWithReservations: FC<FilterWithReservationsProps> = ({ reservations }) => {
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()
  const [ticketNumber, setTicketNumber] = useState("")

  const filteredReservations = reservations.filter(reservation => {
    const arrivalDate = new Date(reservation.arrivalAt || '')
    const isAfterFromDate = fromDate ? arrivalDate >= fromDate : true
    const isBeforeToDate = toDate ? arrivalDate <= toDate : true
    const matchesTicketNumber = reservation.reservationId.toString().includes(ticketNumber) 
    return isAfterFromDate && isBeforeToDate && matchesTicketNumber
  })
  
  return (
    <main className="flex w-full flex-col gap-3">
      <ReservationFilter setFromDate={setFromDate} setToDate={setToDate} setTicketNumber={setTicketNumber} />
      {filteredReservations.length > 0 && filteredReservations.map((res) => (
        <Reservation reservation={res} key={res.reservationId} />
      ))}
    </main>
  )
}

export default FilterWithReservations
