import { FC, useState, HTMLAttributes, Dispatch, SetStateAction } from 'react'
import { Checkbox } from '@/root/ui/admaritime'
import TicketsInput from './TicketsInput'
import cn from 'classnames'
import { useGetTranslations } from '@/root/shared-hooks'

const FilterCell: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return (
    <div
      className={cn('flex justify-start lg:justify-center lg:items-center p-5 border-b lg:border-b-0 lg:border-e border-adm-gray-550 last:border-none', className)}
    >
      {children}
    </div>
  ) 
}

interface ReservationFilterProps {
  setFromDate: Dispatch<SetStateAction<Date | undefined>>
  setToDate: Dispatch<SetStateAction<Date | undefined>>
  setTicketNumber: Dispatch<SetStateAction<string>>
}

const ReservationFilter: FC<ReservationFilterProps> = ({ setFromDate, setToDate, setTicketNumber }) => {
  const [previousETicketsChecked, setPreviousETicketsChecked] = useState(true)
  const [cancelledETicketsChecked, setCancelledETicketsChecked] = useState(true)

  const { getTranslation } = useGetTranslations()

  const togglePreviousETickets = () => {
    setPreviousETicketsChecked(!previousETicketsChecked)
  }

  const toggleCancelledETickets = () => {
    setCancelledETicketsChecked(!cancelledETicketsChecked)
  }

  const CheckboxStyle = cn('w-full flex-col-reverse gap-2 text-xs font-bold text-adm-blue-800')

  return (
    <fieldset>
      <section className={cn('grid lg:grid-rows-1 lg:h-[98px] lg:max-w-[1104px] grid-cols-1 lg:grid-cols-filter rounded-lg bg-adm-gray-200')}>
        <FilterCell>
          <TicketsInput
            label={getTranslation('ticketPrintedTicketNumber')}
            type="search"
            setTicketNumber={setTicketNumber}
          />
        </FilterCell>
        <FilterCell>
          <TicketsInput
            label={getTranslation('ticketPrintedTicketDate')}
            type="date-picker"
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </FilterCell>
        <FilterCell>
          <TicketsInput
            label={getTranslation('ticketPrintedTicketDirection')}
            type="search"
          />
        </FilterCell>
        <FilterCell>
          <Checkbox
            label={getTranslation('ticketPrintedPreviousTicket')}
            variant="success"
            checked={previousETicketsChecked}
            onChange={togglePreviousETickets}
            className={CheckboxStyle}
          />
        </FilterCell>
        <FilterCell>
          <Checkbox
            label={getTranslation('ticketPrintedCancelledTicket')}
            variant="success"
            checked={cancelledETicketsChecked}
            onChange={toggleCancelledETickets}
            className={CheckboxStyle}
          />
        </FilterCell>
      </section>
    </fieldset>
  )
}

export default ReservationFilter
