import React, { FC, useState } from 'react'
import cn from 'classnames'
import { LensIcon } from '@/root/ui/shared/icons/Lens'
import { useGetTranslations } from '@/root/shared-hooks'
import DayPicker from '@/root/components/shared/DayPicker/DayPicker'
import OutsideClickHandler from 'react-outside-click-handler'
import { CrossIcon } from '@storybook/icons'

interface Props {
  label: string
  type: 'search' | 'date-picker'
  setFromDate?: (date: Date | undefined) => void
  setToDate?: (date: Date | undefined) => void
  setTicketNumber?: (ticketNumber: string) => void
}

const TicketsInput: FC<Props> = ({ label, type, setFromDate, setToDate, setTicketNumber }) => {
  const { getTranslation } = useGetTranslations()

  const [fromDate, setLocalFromDate] = useState("")
  const [toDate, setLocalToDate] = useState("")
  const [ticketNumber, setLocalTicketNumber] = useState("")
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false)

  const handleFromDateSelection = (date: Date) => {
    setLocalFromDate(date.toISOString())
    setFromDate?.(date)
    if (toDate && new Date(toDate) < date) {
      setLocalToDate("")
      setToDate?.(undefined)
    }
  }

  const handleClearDates = () => {
    setLocalFromDate("")
    setLocalToDate("")
    setFromDate?.(undefined)
    setToDate?.(undefined)
  }

  const handleClearTicketNumber = () => {
    setLocalTicketNumber("")
    setTicketNumber?.("")
  }

  const handleToDateSelection = (date: Date) => {
    if (date && fromDate && new Date(date) >= new Date(fromDate)) {
      setLocalToDate(date.toISOString())
      setToDate?.(date)
    }
  }

  const formatDate = (date: string) => {
    if (!date || isNaN(Date.parse(date))) return ''
    return new Date(date).toLocaleString('default', { month: 'short' }) + ' ' + new Date(date).getDate()
  }

  const fromDateLabel = formatDate(fromDate) || getTranslation('calendarFilterFrom')
  const toDateLabel = formatDate(toDate) || getTranslation('calendarFilterTo')

  const inputClassName = cn(
    'w-full border h-8 text-sm border-adm-gray-400 bg-white rounded focus:outline-none font-normal', {
      'ps-2': type === 'search'
    }
  )

  return (
    <div className="relative w-full lg:max-w-[235px]">
      <div className="relative grid gap-2 text-xs font-bold text-adm-blue-800">
        <div className='flex justify-between'>
          {label}
          {type === 'date-picker' && (fromDate || toDate) && (
            <button onClick={handleClearDates}>
              <CrossIcon />
            </button>
          )}
          {type === 'search' && ticketNumber && (
            <button onClick={handleClearTicketNumber}>
              <CrossIcon />
            </button>
          )}
        </div>

        <div className="relative grid w-full items-center">
          {type === 'date-picker' ? (
            <div className='flex justify-center lg:flex-none lg:justify-start'>
              <section
                className={inputClassName}
                onClick={() => setIsDayPickerOpen(true)}
              >
                <div className="flex justify-around pt-2 text-adm-gray-350">
                  <span>{fromDateLabel}</span>
                  <span>{toDateLabel}</span>
                </div>
              </section>

              {isDayPickerOpen && (
                <div className="absolute top-full z-20 mt-3 rounded-md bg-white shadow-lg">
                  <OutsideClickHandler onOutsideClick={() => setIsDayPickerOpen(false)}>
                    <div className="flex flex-col md:flex-row">
                      <DayPicker
                        selectedDate={fromDate ? new Date(fromDate) : undefined}
                        onDayClick={handleFromDateSelection}
                        isCustomStyle
                      />
                      <DayPicker
                        selectedDate={toDate ? new Date(toDate) : undefined}
                        onDayClick={handleToDateSelection}
                        isCustomStyle
                      />
                    </div>
                  </OutsideClickHandler>
                </div>
              )}
            </div>
          ) : (
            <>
              <input
                id="reservation-number-filter"
                type="text"
                value={ticketNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setLocalTicketNumber(value)
                    setTicketNumber?.(value)
                  }
                }}
                className={inputClassName}
              />
              <span className="pointer-events-none absolute ltr:right-2 rtl:left-2">
                <LensIcon />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TicketsInput
