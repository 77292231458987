import React, { FC } from 'react'
import { ADMVessel } from '@/root/shared-types/sales-static/sales-static-types'
import { VesselParametersBar } from '@/root/modules/admaritime'
import { VesselCardContainer } from '@/root/modules/admaritime/Fleet/components/VesselCardContainer/VesselCardContainer'

export const VesselCard: FC<ADMVessel> = ({ name, parameters, images }) => {
  const styles = {
    backgroundImage: `url(api/${images[0]?.url})`,
  }

  return (
    <VesselCardContainer>
      <div className="flex h-[134px] rounded-t-xl bg-adm-gray-300 bg-cover bg-center bg-no-repeat" style={styles}>
        <p className="self-end pb-2 ps-[10px] text-base font-semibold text-white drop-shadow-[0_0_5px_rgba(0,0,0,1)]">
          {name}
        </p>
      </div>
      <VesselParametersBar {...parameters} />
    </VesselCardContainer>
  )
}
