import { FC, useState } from 'react'
import { ContentContainer } from '@/root/components/shared'
import { AppLayout } from '@/root/modules/admaritime'
import { Banner } from '@/root/ui/admaritime'
import { useGetTranslations, useReservation } from '@/root/shared-hooks'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'
import Loader from '@/root/ui/shared/Loader/Loader'

const RefundResultPage: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { reservation } = useReservation()
  const [totalRefundAmount] = useState<number>(0)
  const { formatMoney } = useAppConfig()

  if (!reservation) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex justify-center py-40">
            <Loader />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }

  return (
    <AppLayout>
      <ContentContainer>
        <div className="my-10 flex flex-col gap-8 rounded-sm bg-white px-6 py-14 shadow-sm">
          <Banner
            body={
              <span className="font-normal">
                {getTranslation('ticketWasRefund', {
                  reservationId: reservation?.reservationId,
                })}
              </span>
            }
            variant="success"
          />
          <p
            className="max-w-[450px] self-center text-center tracking-[1px] text-neutral"
            dangerouslySetInnerHTML={{
              __html: getTranslation('ticketWasRefundTipMaritime', {
                refundAmount: `<strong class="text-black">${formatMoney(totalRefundAmount)}</strong>`,
              }),
            }}
          />
          <p className="mt-10 text-center text-adm-gray-400">{getTranslation('mayClose')}</p>
        </div>
      </ContentContainer>
    </AppLayout>
  )
}

export default RefundResultPage
